import Button from '@mui/material/Button';
import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import {
  cardSetAtom,
  startAtom,
  endAtom,
  graphDataAtom,
  isLoadingAtom,
  startQcAtom,
  endQcAtom
} from 'pages/Qc/state';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFetchGraphData } from 'utils/index';

// =============================================================================
interface GetDataButtonProps {
  disabled?: boolean;
}

// =============================================================================
export default function GetDataButton({
  disabled = false
}: GetDataButtonProps) {
  const start = useRecoilValue(startAtom);
  const end = useRecoilValue(endAtom);
  const cardSet = useRecoilValue(cardSetAtom);
  const { fetchGraphData } = useFetchGraphData();

  return (
    <div>
      <Button
        variant='secondary'
        color='dark'
        disabled={disabled}
        onClick={handleClick}
      >
        <AddchartRoundedIcon />
        Plot
      </Button>
    </div>
  );

  async function handleClick() {
    fetchGraphData(start, end, cardSet);
  }
}
