import React, { useEffect, useMemo, useState } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import Box from '@mui/material/Box';
import TimelineIcon from '@mui/icons-material/Timeline';
import Button from '@mui/material/Button';
import _ from 'lodash';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import MenuItem from '@mui/material/MenuItem';
import GraphTimeRange from './GraphTimeRange';
import custom_palette from 'theme/custom_palettes';
import custom_palettes from 'theme/custom_palettes';
import InputLabel from '@mui/material/InputLabel';
import UploadFile from 'pages/OnBoarding/UploadFile';
import {
  activeGraphAtom,
  isLoadingAtom,
  graphDataAtom,
  boxDataAtom,
  cardSetAtom,
  startQcAtom,
  endQcAtom,
  startAtom,
  endAtom
} from './state';
import moment from 'moment';
import api from 'utils/api';
import LineGraph from './LineGraph';
import BoxPlotGraph from './BoxPlotGraph';
import Typography from '@mui/material/Typography';
import custom_typographys from 'theme/custom_typographys';
import { processBoxData, processScatterData } from 'utils/data';
import Cookies from 'universal-cookie';
import { exportRawData } from './DataDownloader';
import { useFetchGraphData } from 'utils/index';
// =============================================================================
const cookies = new Cookies();

const BUTTON_MARGIN = 16;
const BUTTON_HEIGHT = 32;
const custom_select_wrapper_style = {
  width: '100%',
  margin: '0 auto',
  // border: `1px solid ${custom_palettes.gray[100]}`,
  borderRadius: '24px',
  marginBottom: '12px',
  ...custom_typographys.body_regular,
  color: custom_palettes.blue[900],
  padding: '0 16px'
};

// =============================================================================
const chart_options_wrapper_style = {
  height: 80,
  backgroundColor: custom_palette.yellow[50],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const active_style = {
  backgroundColor: 'yellow.200',
  border: '2px solid black',
  '&:hover': {
    backgroundColor: 'yellow.200'
  }
};

const normal_style = {
  backgroundColor: 'transparent',
  border: '2px solid rgba(0, 0, 0, 0.3)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'yellow.200'
  }
};

// =============================================================================
interface ChartOptionsProps {}

export default function ChartOptions(props: ChartOptionsProps) {
  const [value, setValue] = React.useState(0);
  const [binSize, setBinSize] = React.useState('1D');

  const [activeGraph, setActiveGraph] = useRecoilState(activeGraphAtom);
  const cardSet = useRecoilValue(cardSetAtom);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);
  const startQc = useRecoilValue(startQcAtom);
  const endQc = useRecoilValue(endQcAtom);
  const start = useRecoilValue(startAtom);
  const end = useRecoilValue(endAtom);
  const [graphData, setGraphData]: [any, any] = useRecoilState(graphDataAtom);
  const [boxData, setBoxData]: [any, any] = useRecoilState(boxDataAtom);
  const [qcFlag, setQcFlag] = useState(0);
  const [focus, setFocus] = useState();
  const { fetchGraphData } = useFetchGraphData();

  const OPTIONS = ['Line Chart', 'Box Plot'];

  const charts = useMemo(
    () => [
      <LineGraph data={processGraphData(graphData).data} focus={focus} />,
      <BoxPlotGraph
        // @ts-ignore
        data={boxData}
        handleFocus={(index: any) => {
          setValue(0);
          const label = boxData[0].labels[index];
          const ts: any = moment(label, 'D MMM YY').startOf('day').valueOf();
          setFocus(ts);
        }}
        binSize={binSize}
      />
      // <ScatterPlotGraph data={formattedScatterData()} />
    ],
    [graphData, binSize, boxData]
  );

  useEffect(() => {
    const binSizes: any =
      boxData && boxData.length ? Object.keys(boxData[0]) : ['1D'];
    setBinSize(binSizes[0]);
  }, [boxData]);

  useEffect(() => {
    setValue(OPTIONS.indexOf(activeGraph));
  }, [activeGraph]);

  function processGraphData(graphData: any) {
    return {
      data: _.map(graphData, 'data'),
      accuracy: _.map(graphData, 'accuracy')
    };
  }

  function formattedScatterData() {
    return processScatterData(processGraphData(graphData));
  }

  function formattedBoxData() {
    return processBoxData(processGraphData(graphData), binSize);
  }

  const getTitle = () => {
    const result: string = cardSet.reduce(
      (title: any, card: any) => title + card.parameterDesc + ' and ',
      ''
    ) as string;

    return result.substring(0, result.length - 5);
  };

  const handleReload = async () => {
    fetchGraphData(start, end, cardSet);
  };

  const handleQcFlag = (event: any) => {
    setQcFlag(event.target.value);
  };

  const handleQc = (sQc?: any, eQc?: any) => {
    const myStartQc = { ...startQc, ...sQc };
    const myEndQc = { ...endQc, ...eQc };
    if (!Number.isInteger(qcFlag)) {
      alert('Please select a qc flag');
      return;
    }

    let confirmMsg;
    if (myEndQc.ts) {
      confirmMsg = `You are QC'ing data from ${moment(myStartQc.ts).format(
        'MMM-DD-yyyy hh:mm:ss'
      )} to ${moment(myEndQc.ts).format('MMM-DD-yyyy hh:mm:ss')}`;
    } else {
      confirmMsg = `You are QC'ing data for ${moment(myStartQc.ts).format(
        'MMM-DD-yyyy hh:mm:ss'
      )}`;
    }

    if (confirm(confirmMsg)) {
      const timestamps = graphData[0].rawData
        .slice(myStartQc.i, myEndQc.ts ? myEndQc.i + 1 : myStartQc.i + 1)
        .map((obj: any) => obj.dataTs);
      cardSet.map(async (card: any) => {
        try {
          setIsLoading(true);
          const res = await api.post('/api/rawData/qc/otf', {
            location: card.location,
            parameterDesc: card.parameterDesc,
            timestamps,
            qcFlag: qcFlag,
            depth: card.depth.toUpperCase().replace(/ /g, '_'),
            sensorDetailsHash: card.sensorDetailsHash
          });
          alert('Successfully updated, Fetching latest data...');
          await handleReload();
        } catch (err: any) {
          alert(err.response.data.message);
          setIsLoading(false);
        }
      });
    }
  };

  const handleCompleteQc = () => {
    handleQc({ ts: start }, { ts: end });
  };

  const handleDownload = async () => {
    alert(
      'Note: The downloaded data will be monthly basis. If the chosen time range is less than a month or spans for more than a month - then first month data will be downloaded'
    );
    cardSet.map(async (card: any) => {
      try {
        setIsLoading(true);
        const res = await api.post('/admin/rawData/download', {
          parameterId: card.id.toString(),
          from: start,
          to: end,
          location: card.location,
          depth: card.depth.toUpperCase().replace(/ /g, '_'),
          parameterDesc: card.parameterDesc,
          sensorDetailsHash: card.sensorDetailsHash
        });
        setIsLoading(false);
        exportRawData(res.data.fileName, res.data.data);
        alert('Successfully downloaded');
      } catch (err: any) {
        alert(err.response.data.message);
        setIsLoading(false);
      }
    });
  };

  return (
    <div>
      <Box style={chart_options_wrapper_style}>
        {generateOptionButtons(OPTIONS)}
      </Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography
          variant='body_large_strong'
          color='blue.900'
          style={{ paddingLeft: 32 }}
        ></Typography>
        {getTitle() ? (
          <span
            style={{
              fontSize: '14px'
            }}
          >
            -To QC, click on data points: Click start point and end point to
            select a range. Click outside of the line to deselect.-
          </span>
        ) : null}
        <GraphTimeRange />
      </div>
      {charts[value].props.data.length !== 0 && !isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '32px'
          }}
        >
          <div
            style={{
              display: 'flex'
            }}
          >
            <div>
              <div>
                <span
                  style={{
                    display: 'inline-block',
                    width: '50px',
                    borderBottom: '3px solid',
                    marginBottom: '3px'
                  }}
                ></span>{' '}
                QC-ed Data
              </div>
              <div>
                <span
                  style={{
                    display: 'inline-block',
                    width: '50px',
                    borderBottom: '3px dashed',
                    marginBottom: '3px'
                  }}
                ></span>{' '}
                Pre-QC-ed Data
              </div>
            </div>
            <div
              style={{
                marginLeft: '20px'
              }}
            >
              <div>
                <span
                  style={{
                    display: 'inline-block',
                    width: '50px',
                    borderBottom: `3px solid ${custom_palette.yellow[400]}`,
                    marginBottom: '3px'
                  }}
                ></span>{' '}
                QC-Flag 0
              </div>
              <div>
                <span
                  style={{
                    display: 'inline-block',
                    width: '50px',
                    borderBottom: `3px solid ${custom_palette.green[500]}`,
                    marginBottom: '3px'
                  }}
                ></span>{' '}
                QC-Flag 1
              </div>
            </div>
            <div
              style={{
                marginLeft: '20px'
              }}
            >
              <div>
                <span
                  style={{
                    display: 'inline-block',
                    width: '50px',
                    borderBottom: `3px solid ${custom_palette.green[50]}`,
                    marginBottom: '3px'
                  }}
                ></span>{' '}
                QC-Flag 2
              </div>
              <div>
                <span
                  style={{
                    display: 'inline-block',
                    width: '50px',
                    borderBottom: `3px solid ${custom_palette.error.main}`,
                    marginBottom: '3px'
                  }}
                ></span>{' '}
                QC-Flag 3
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              flexWrap: 'wrap'
            }}
          >
            <div
              style={{
                width: '20%'
              }}
            >
              <InputLabel
                id='qc-flag-label'
                style={{
                  color: 'black'
                }}
              >
                Select qc flag
              </InputLabel>
              <Select
                onChange={handleQcFlag}
                value={qcFlag}
                labelId='qc-flag-label'
                sx={{
                  ...custom_select_wrapper_style,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: custom_palettes.gray[100]
                  }
                }}
                IconComponent={() => <KeyboardArrowDownSharpIcon />}
              >
                <MenuItem value={0}>0 - not QC'ed</MenuItem>
                <MenuItem value={1}>1 - Good</MenuItem>
                <MenuItem value={2}>2 - Dubious</MenuItem>
                <MenuItem value={3}>3 - Bad</MenuItem>
              </Select>
            </div>
            <div
              style={{
                marginLeft: '10px'
              }}
            >
              <Button
                variant='secondary'
                color='dark'
                onClick={handleQc}
                disabled={!!!startQc.ts}
              >
                Submit QC for Selection
              </Button>
            </div>
            {/* <div
              style={{
                marginLeft: '10px'
              }}
            >
              <Button
                variant='secondary'
                color='dark'
                onClick={handleCompleteQc}
                disabled={!!startQc.ts}
              >
                Submit QC for all plotted points
              </Button>
            </div> */}
            <div
              style={{
                marginLeft: '10px'
              }}
            >
              <Button
                variant='secondary'
                color='dark'
                onClick={handleDownload}
                disabled={!!startQc.ts}
              >
                Download CSV
              </Button>
            </div>
            <div
              style={{
                marginLeft: '10px'
              }}
            >
              <UploadFile
                accept='.csv'
                title='Upload QC'
                handleChange={(e: any) => {
                  const csvFile = e.target.files ? e.target.files[0] : null;
                  if (csvFile) {
                    cardSet.map(async (card: any) => {
                      try {
                        setIsLoading(true);
                        const fd = new FormData();
                        fd.append('file', csvFile);
                        fd.append('parameterDesc', card.parameterDesc);
                        fd.append('sensorDetailsHash', card.sensorDetailsHash);
                        fd.append('location', card.location);
                        fd.append(
                          'depth',
                          card.depth.toUpperCase().replace(/ /g, '_')
                        );
                        await api.post('/api/rawData/qc/upload', fd, {
                          headers: {
                            'Content-Type': 'multipart/form-data'
                          }
                        });
                        alert('Successfully uploaded and updated QC data');
                        await handleReload();
                      } catch (err: any) {
                        alert(err.response.data.message);
                        setIsLoading(false);
                      }
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {value === 1 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginRight: 48,
            marginTop: 12,
            alignItems: 'center'
          }}
        >
          <Typography fontSize={10} color='blue.900'>
            BIN SIZE
          </Typography>
          {generateBinSizes()}
        </div>
      )}
      {charts[value].props.data.length === 0 && !isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300
          }}
        >
          <TimelineIcon fontSize='large' />
          <Typography>No Data</Typography>
        </div>
      ) : (
        <div style={{ marginTop: 20 }}>{charts[value]}</div>
      )}
    </div>
  );

  function generateBinSizes() {
    const binSizes: any =
      boxData && boxData.length ? Object.keys(boxData[0]) : ['1D'];
    return binSizes.map((_binSize: string, index: any) => (
      <Typography
        key={index}
        fontSize={12}
        color={_binSize === binSize ? 'blue.600' : 'blue.400'}
        style={{
          marginLeft: 12,
          cursor: 'pointer',
          textDecoration: _binSize === binSize ? 'underline' : 'none'
        }}
        onClick={() => setBinSize(_binSize)}
      >
        {_binSize}
      </Typography>
    ));
  }

  function generateOptionButtons(options: string[]) {
    return options.map((option: string, index: number) => {
      return (
        <Button
          key={index}
          variant='chip'
          onClick={() => {
            setActiveGraph(OPTIONS[index]);
            setValue(index);
          }}
          sx={index === value ? active_style : normal_style}
          style={{
            marginLeft: BUTTON_MARGIN,
            height: BUTTON_HEIGHT,
            color: 'black',
            opacity: 1
          }}
        >
          {option}
        </Button>
      );
    });
  }
}
