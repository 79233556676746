import axios from 'axios';
import Cookies from 'universal-cookie';
import momentTZ from 'moment-timezone';

const oas3Api = axios.create({
  baseURL: 'https://dev.mesn.arl-nus.org/service',
  headers: {
    'Content-Type': 'application/json'
  }
});

export { oas3Api };

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  (config: any) => {
    const cookies = new Cookies();
    const tokens = cookies.get('admin-tokens');
    if (tokens) {
      const { accessToken } = tokens;
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error;

    if (
      originalRequest.response.status === 401 &&
      originalRequest.response.data.message === 'Token Expired'
    ) {
      const cookies = new Cookies();
      try {
        const tokens = cookies.get('admin-tokens');
        if (tokens) {
          const refreshRes = await axios.post(
            import.meta.env.VITE_API_URL + '/public/refreshAccessToken',
            { refreshToken: tokens.refreshToken }
          );
          cookies.set('admin-tokens', refreshRes.data, {
            path: '/',
            secure: true
          });
          // Retry the original request with the new token
          originalRequest.config.headers.Authorization = `Bearer ${refreshRes.data.accessToken}`;
          return axios(originalRequest.config);
        }
      } catch (error) {
        // Handle refresh token error or redirect to login
        console.log(error);
        cookies.remove('admin-tokens');
        window.alert('Token Expired. Please login');
        // @ts-ignore
        window.location = '/login';
      }
    }

    if (
      originalRequest.response.status === 409 &&
      originalRequest.response.data.message === 'Force Access Token Refresh'
    ) {
      const cookies = new Cookies();
      try {
        const tokens = cookies.get('admin-tokens');
        if (tokens) {
          const refreshRes = await axios.post(
            import.meta.env.VITE_API_URL + '/public/refreshAccessToken',
            { refreshToken: tokens.refreshToken, forced: true }
          );
          cookies.set('admin-tokens', refreshRes.data, {
            path: '/',
            secure: true
          });
          // Retry the original request with the new token
          originalRequest.config.headers.Authorization = `Bearer ${refreshRes.data.accessToken}`;
          return axios(originalRequest.config);
        }
      } catch (error) {
        // Handle refresh token error or redirect to login
        console.log(error);
        cookies.remove('admin-tokens');
        window.alert('Token Expired. Please login');
        // @ts-ignore
        window.location = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default api;

export const fetchParams = async () => {
  const cookies = new Cookies();
  const unitMap: any = {
    'Air Pressure': 'mbar',
    CDOM: 'ppb',
    Chlorophyll: 'RFU',
    Nitrate: 'μmol/L',
    'Dissolved Oxygen': 'μmol/L',
    'Crude Oil': 'ppb',
    pH: 'pH',
    Phycoerythrin: 'μg/L',
    'Refined Fuels': 'ppm',
    'Relative Humidity': '%',
    Salinity: 'PSU',
    'Solar Irradiance': 'W/m²',
    'Water Temperature': '°C',
    'Air Temperature': '°C',
    Turbidity: 'NTU',
    'Wind Speed': 'm/s',
    pCO2: 'ppm',
    'Secchi depth': 'm',
    'Total alkalinity': 'µeq/kg',
    TSS: 'mg/L',
    DIC: 'µmol/kg',
    NOx: 'µmol/l',
    Phosphate: 'µmol/l',
    Silicate: 'µmol/l',
    Pb: 'pmol/kg',
    Zn: 'nmol/kg',
    Cu: 'nmol/kg',
    Cd: 'nmol/kg',
    'Current Velocity': 'cm/s',
    'Current Direction': '°'
  };

  if (cookies.get('admin-tokens')) {
    const params = await api.get('/public/mesnParameters');
    return params.data.map((param: any) => ({
      ...param,
      unit: unitMap[param.parameterDesc]
    }));
  }
  return {};
};

export const fetchParamsWithPermissions = async (userId: any) => {
  const cookies = new Cookies();
  const unitMap: any = {
    'Air Pressure': 'mbar',
    CDOM: 'ppb',
    Chlorophyll: 'RFU',
    Nitrate: 'μmol/L',
    'Dissolved Oxygen': 'μmol/L',
    'Crude Oil': 'ppb',
    pH: 'pH',
    Phycoerythrin: 'μg/L',
    'Refined Fuels': 'ppm',
    'Relative Humidity': '%',
    Salinity: 'PSU',
    'Solar Irradiance': 'W/m²',
    'Water Temperature': '°C',
    'Air Temperature': '°C',
    Turbidity: 'NTU',
    'Wind Speed': 'm/s',
    pCO2: 'ppm',
    'Secchi depth': 'm',
    'Total alkalinity': 'µeq/kg',
    TSS: 'mg/L',
    DIC: 'µmol/kg',
    NOx: 'µmol/l',
    Phosphate: 'µmol/l',
    Silicate: 'µmol/l',
    Pb: 'pmol/kg',
    Zn: 'nmol/kg',
    Cu: 'nmol/kg',
    Cd: 'nmol/kg',
    'Current Velocity': 'cm/s',
    'Current Direction': '°'
  };

  if (cookies.get('admin-tokens')) {
    const params = await api.get(`/admin/parametersWithPermissions/${userId}`);
    return params.data.map((param: any) => ({
      ...param,
      unit: unitMap[param.parameterDesc]
    }));
  }
  return {};
};

export const queryLineData = async (start: any, end: any, card: any) => {
  const response = await api.post(
    `${import.meta.env.VITE_API_URL}/api/rawData/line`,
    {
      to: end,
      from: start,
      location: card.location,
      depth: card.depth.toUpperCase().replace(/ /g, '_'),
      parameterDesc: card.parameterDesc,
      sensorDetailsHash: card.sensorDetailsHash
    }
  );

  const data = response.data.map((obj: any, index: any) => {
    return {
      x: momentTZ.utc(obj._time).tz('Asia/Singapore').format(),
      y: Number(obj.qcValue),
      qcFlag: obj.qcFlag
    };
  });
  // @ts-ignore
  return {
    data,
    accuracy: card.accuracy,
    isCruiseParam: card.isCruiseParam,
    rawData: response.data
  };
};

export const queryBoxData = async (
  start: any,
  end: any,
  card: any,
  interval: any
) => {
  const response = await api.post(
    `${import.meta.env.VITE_API_URL}/api/rawData/box`,
    {
      to: end,
      from: start,
      location: card.location,
      depth: card.depth.toUpperCase().replace(/ /g, '_'),
      parameterDesc: card.parameterDesc,
      sensorDetailsHash: card.sensorDetailsHash,
      interval
    }
  );
  // @ts-ignore
  return {
    data: response.data,
    accuracy: card.accuracy,
    isCruiseParam: card.isCruiseParam,
    rawData: response.data
  };
};

export const getQuantity = (quantity: any): string => {
  return quantity.toLowerCase().replace(/\s/g, '-');
};
