import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { startAtom, endAtom } from 'pages/Qc/state';

// =============================================================================
export default function GraphTimeRange() {
  const [start, setStart] = useRecoilState(startAtom);
  const [end, setEnd] = useRecoilState(endAtom);

  // useEffect(() => {
  //   setStart(moment().subtract(2, 'weeks').startOf('day').unix() * 1000);
  // }, [start]);

  // useEffect(() => {
  //   setEnd(moment(end).endOf('day').unix() * 1000);
  // }, [end]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 48,
        marginBottom: 0
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          renderInput={(props) => (
            <TextField
              {...props}
              inputProps={{ ...props.inputProps, readOnly: true }}
            />
          )}
          label='Start'
          value={moment(start).format('YYYY-MM-DD HH:mm')}
          disableHighlightToday
          inputFormat='DD-MMM-YYYY hh:mm a'
          onChange={(newValue: any) => {
            setStart(moment(newValue.unix() * 1000).unix() * 1000);
          }}
        />
        <div style={{ width: 16 }} />
        <DateTimePicker
          renderInput={(props) => (
            <TextField
              {...props}
              inputProps={{ ...props.inputProps, readOnly: true }}
            />
          )}
          label='End'
          value={moment(end).format('YYYY-MM-DD HH:mm')}
          inputFormat='DD-MMM-YYYY hh:mm a'
          onChange={(newValue: any) => {
            setEnd(moment(newValue.unix() * 1000).unix() * 1000);
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
