import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import CustomLink from 'components/common/CustomLink';
import { useRecoilValue } from 'recoil';
import { useState, useId } from 'react';
import {
  cardSetAtom,
  paramsAtom,
  useAddCard,
  useUpdateCard
} from 'pages/Qc/state';
import { jwtData } from 'pages/OnBoarding/state';
import _ from 'lodash';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { If, Then } from 'react-if';
import { CONSTANTS } from 'utils/constants';

// =============================================================================

const locationMap = Object.values(CONSTANTS.LOCATION_ACRONYM_TO_FULL_FORM_MAP);

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box
          sx={{
            p: 3,
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '16px'
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

// =============================================================================
interface PopoverOptionsProps {
  anchorEl: any;
  onClose: any;
  anchorPosition?: { top: number; left: number };
  setAnchorEl: any;
  mode: 'addCard' | 'updateCard';
  cardIndex?: string;
  location?: string;
}

export default function PopoverOptions(props: PopoverOptionsProps) {
  const open = Boolean(props.anchorEl);

  return (
    <Popover
      open={open}
      anchorReference={props.anchorPosition ? 'anchorPosition' : 'anchorEl'}
      anchorPosition={props.anchorPosition}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      sx={{
        '& .MuiPopover-paper': {
          boxShadow: `0px 8px 10px rgba(0, 0, 0, 0.16)`,
          borderRadius: '16px',
          backgroundColor: 'white.50'
        }
      }}
    >
      <PopoverView
        cardIndex={props.cardIndex}
        mode={props.mode}
        setAnchorEl={props.setAnchorEl}
        handleClose={props.onClose}
        location={props.location}
      />
    </Popover>
  );
}

// =============================================================================
const popover_view_wrapper_style = {
  padding: '26px 16px 16px 16px'
};

interface PopoverViewProps {
  cardIndex?: string;
  setAnchorEl: any;
  mode: 'addCard' | 'updateCard';
  handleClose: () => void;
  location?: string;
}

const PopoverView = (props: PopoverViewProps) => {
  const [value, setValue] = useState(
    props.location ? locationMap.indexOf(props.location) : 0
  );
  const cardSet: any = useRecoilValue(cardSetAtom);
  const paramsData: any = useRecoilValue(paramsAtom);
  const addCard = useAddCard();
  const updateCard = useUpdateCard();
  const decoded: any = useRecoilValue(jwtData);

  const getTabsAndTabPanels = () => {
    const locationBasedParams = _.groupBy(paramsData.data, 'location');

    // To maintain location order in the UI
    let locationBasedOrderedParams: any = {};
    Object.keys(CONSTANTS.LOCATION_ACRONYM_TO_FULL_FORM_MAP).map(
      (location: string) => {
        if (locationBasedParams[location]) {
          locationBasedOrderedParams[location] = locationBasedParams[location];
        }
      }
    );

    return (
      <>
        <Tabs value={value} onChange={handleChange}>
          {Object.keys(locationBasedOrderedParams).map(
            (location: any, idx: any) => (
              <Tab
                label={CONSTANTS.LOCATION_ACRONYM_TO_FULL_FORM_MAP[location]}
                key={`Tab-${idx}`}
              />
            )
          )}
        </Tabs>
        {Object.values(locationBasedOrderedParams).map(
          (pData: any, idx: any) => (
            <TabPanel value={value} index={idx} key={`TabPanel-${idx}`}>
              {pData.map((param: any, index: number) => (
                <div key={`param-${idx}-${index}`} style={{ padding: 16 }}>
                  <CustomLink
                    variant='button'
                    color='blue.800'
                    onClick={() =>
                      props.mode === 'addCard'
                        ? handleAddCard(param)
                        : handleUpdateCard(param)
                    }
                  >
                    {param.parameterDesc}
                  </CustomLink>
                  &nbsp;&nbsp;
                  <Typography variant='button' color='gray.700'>
                    {param.unit === '' ? '' : `(${param.unit})`}
                  </Typography>
                  <div>
                    <Typography variant='body_regular_strong' color='gray.500'>
                      {param.sensorClass}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant='body_regular_strong' color='gray.500'>
                      {param.sensorId}
                    </Typography>
                  </div>
                </div>
              ))}
            </TabPanel>
          )
        )}
      </>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div style={popover_view_wrapper_style}>
      <IconButton
        size='large'
        style={{ float: 'right' }}
        onClick={props.handleClose}
      >
        <CloseIcon sx={{ color: 'blue.800' }} />
      </IconButton>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {getTabsAndTabPanels()}
      </div>
    </div>
  );

  function handleAddCard(option: any) {
    const parameter = option.parameterDesc;

    if (
      cardSet[0] &&
      cardSet[0].location === option.location &&
      cardSet[0].parameterDesc === parameter
    ) {
      alert(`${parameter} was already added.`);
      return;
    }

    const newCardLayout = {
      location: locationMap[value],
      ...option,
      unit: option.unit === '' ? '' : `(${option.unit})`,
      isCruiseParam: option.category === 'MONTHLY_CRUISE_PARAMETERS',
      depth: 'Shallow'
    };

    addCard(newCardLayout);
    props.setAnchorEl(null);
  }

  function handleUpdateCard(option: any) {
    const parameter = option.parameterDesc;
    const location = locationMap[value];
    if (
      cardSet[0] &&
      cardSet[0].location === location &&
      cardSet[0].parameterDesc === parameter
    ) {
      alert(`${parameter} was already added.`);
      return;
    }

    if (
      cardSet[1] &&
      cardSet[1].location === location &&
      cardSet[1].parameterDesc === parameter
    ) {
      alert(`${parameter} was already added.`);
      return;
    }

    // @ts-ignore
    const updatedCardLayout = {
      location,
      ...option,
      unit: option.unit === '' ? '' : `(${option.unit})`,
      isCruiseParam: option.category === 'MONTHLY_CRUISE_PARAMETERS',
      depth: 'Shallow'
    };
    updateCard(props.cardIndex, updatedCardLayout);
    props.setAnchorEl(null);
  }
};
