import FileSaver from 'file-saver';
import moment from 'moment-timezone';

export const exportRawData = (fileName: string, data: any) => {
  const headers = ['ID', 'DataTs', 'DateTime(SGT)', 'Value', 'QcFlag', 'Unit'];
  const rows = [
    headers,
    ...data.map((obj: any) => [
      obj.dataId,
      `#${moment(obj._time).valueOf()}`,
      moment
        .utc(obj._time)
        .tz('Asia/Singapore')
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      obj.qcValue,
      obj.qcFlag,
      obj.unit
    ])
  ];

  let csvContent = new File(
    [rows.map((e) => e.join(',')).join('\r\n')],
    fileName + '.csv',
    {
      type: 'text/csv;charset=utf-8'
    }
  );
  FileSaver.saveAs(csvContent);
};
